import { h, Component } from 'preact';
import { Link } from 'react-router-dom'
import WbLogo from '../wb-logo';
import style from './style';
import classNames from 'classnames/bind';
import NavItem from '../nav-item';
import withSizes from 'react-sizes';
import { withRouter } from 'react-router';
import NavItemExternal from '../nav-item-external';

let cx = classNames.bind(style);

class WbNavBar extends Component {
  
  pages = {
    '': 'artists',
    artists: 'artists',
    work: 'work',
    class: 'class',
    contact: 'contact',
    philosophy: 'philosophy',
    shop: 'shop'
  }

  toggleExpand = (e) => {
    this.setState({
      expanded: !this.state.expanded
    });

    return true;
  }
  
  constructor() {
    super();
    this.state = {
      popoverOpen: false,
      showCopyConfirm: false,
      expanded: false // Only valid for mobile
    };
  }

  render() {
    let currentPage = '';
    if (typeof window !== 'undefined') {
      currentPage = this.pages[window.location.pathname.split('/')[1]];
    }

    return (
      <nav class={cx('wb-nav-bar')}>
        {!this.props.isSmall && (
          <div>
            <Link class={cx('wb-nav-bar__logo')} to="/">
              <WbLogo />
            </Link>
            {/* <div class={cx('wb-nav-bar__nav-item')}>
              <NavItem title="ARTISTS" to="/" />
            </div> */}
            <div class={cx('wb-nav-bar__nav-item')}>
              <NavItem title="WORK" to="/work" />
            </div>
            <div class={cx('wb-nav-bar__nav-item')}>
              <NavItemExternal title="CLASS" to="https://groundgrooves.com" />
            </div>
            <div class={cx('wb-nav-bar__nav-item')}>
              <NavItem title="CONTACT" to="/contact" />
            </div>
            <div class={cx('wb-nav-bar__nav-item')}>
              <NavItem title="SHOP" to="/shop" />
            </div>
            <Link class={cx('wb-nav-bar__philosophy')} to="/philosophy">
              <img class={cx('wb-nav-bar__philosophy-item', 'wb-nav-bar__philosophy-item--party')} src="/assets/party.svg" />
              <img class={cx('wb-nav-bar__philosophy-item', 'wb-nav-bar__philosophy-item--people')} src="/assets/people.svg" />  
              <img class={cx('wb-nav-bar__philosophy-item', 'wb-nav-bar__philosophy-item--money')} src="/assets/money.svg" />  
            </Link>
          </div>
        )}
        {this.props.isSmall && (
          <div>
            <div class={cx('wb-nav-bar__main')} onClick={this.toggleExpand}>
              <div class={cx('wb-nav-bar__logo')} >
                <WbLogo />
              </div>
              <div class={cx('wb-nav-bar__expand-collapse', { 'wb-nav-bar__expand-collapse--expanded': this.state.expanded,  'wb-nav-bar__expand-collapse--collapsed': !this.state.expanded })} />
              <div class={cx('wb-nav-bar__current')}>
                {currentPage === 'philosophy' 
                  ? (
                    <div class={cx('wb-nav-bar__current-philosophy')}>
                      <img class={cx('wb-nav-bar__current-philosophy-item', 'wb-nav-bar__current-philosophy-item--party')} src="/assets/party.svg" />
                      <img class={cx('wb-nav-bar__current-philosophy-item', 'wb-nav-bar__current-philosophy-item--people')} src="/assets/people.svg" />  
                      <img class={cx('wb-nav-bar__current-philosophy-item', 'wb-nav-bar__current-philosophy-item--money')} src="/assets/money.svg" /> 
                    </div>
                  ) : currentPage
                }
              </div>
            </div>
            <div class={cx('wb-nav-bar__nav-items', { 'wb-nav-bar__nav-items--expanded': this.state.expanded })}>
              <div class={cx('wb-nav-bar__nav-item')} onclick={this.toggleExpand}>
                <NavItem title="ARTISTS" to="/" />
              </div>
              <div class={cx('wb-nav-bar__nav-item')} onclick={this.toggleExpand}>
                <NavItem title="WORK" to="/work" />
              </div>
              <div class={cx('wb-nav-bar__nav-item')} onclick={this.toggleExpand}>
                <NavItemExternal title="CLASS" to="https://groundgrooves.com" />
              </div>
              <div class={cx('wb-nav-bar__nav-item')} onclick={this.toggleExpand}>
                <NavItem title="CONTACT" to="/contact" />
              </div>
              <div class={cx('wb-nav-bar__nav-item')} onclick={this.toggleExpand}>
                <NavItem title="SHOP" to="/shop" />
              </div>
              <div class={cx('wb-nav-bar__nav-item')} onclick={this.toggleExpand}>
                <NavItem to="/philosophy">
                  <img class={cx('wb-nav-bar__philosophy-item', 'wb-nav-bar__philosophy-item--party')} src="/assets/party.svg" />
                  <img class={cx('wb-nav-bar__philosophy-item', 'wb-nav-bar__philosophy-item--people')} src="/assets/people.svg" />  
                  <img class={cx('wb-nav-bar__philosophy-item', 'wb-nav-bar__philosophy-item--money')} src="/assets/money.svg" /> 
                </NavItem>
              </div>
            </div>
          </div>
        )}
      </nav>
    );
  }
}

const mapSizesToProps = sizes => ({
  isSmall: withSizes.isStDesktop(sizes)
});

export default withRouter(withSizes(mapSizesToProps)(WbNavBar));