/* eslint "react/jsx-no-bind": 0 */
import { h, Component } from 'preact';

// Code-splitting is automated for routes
import Artists from '../routes/artists';
import Contact from '../routes/contact';
import WbNavBar from './wb-nav-bar';
import GridBg from '../components/gridBg';
import { TransitionGroup } from 'react-transition-group';
import Transition from 'react-transition-group/Transition';
import Work from '../routes/work';
import WbClass from '../routes/wb-class';
import Philosophy from '../routes/philosophy';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Merch from '../routes/shop';

export default class App extends Component {

  onEnter = (node, isAppearing) => {
    this.updateStyles();
  }

  onEntering = (node, isAppearing) => {
    // console.log('Entering: ', node);
  }

  onEntered = (node, isAppearing) => {
    // This is here because the resize observer polyfill for Safari
    // returns an incorrect value when the componente initially mounts
    // offscreen which messes up the sizing of the svg arrows.  By
    // triggering a resize event once the transition is done, we get
    // the correct values and the arrows display correctly.
    window.dispatchEvent(new Event('resize'));
    // console.log('Entered: ', node);
  }

  onExit = (node) => {
    // console.log('Exit: ', node);
  }

  onExiting = (node) => {
    // console.log('Exiting: ', node);
  }

  onExited = (node) => {
    // console.log('Exited: ', node);
  }

  updateStyles = (skipResize) => {
    if (typeof window !== 'undefined') {
      const isPortrait = window.innerHeight > window.innerWidth;
      
      const contentStyle = {
        height: window.innerHeight,
        width: window.innerWidth
      };

      if (skipResize) {
        const containerStyle = Object.assign(
          {},
          this.state.containerStyle,
          {
            width: isPortrait ? window.innerHeight * 2 : window.innerWidth * 2,
            height: isPortrait ? window.innerHeight * 2 : window.innerWidth * 2,
          }
        )

        const contentStyle = Object.assign(
          {}, 
          this.state.contentStyle, 
          { 
            width: window.innerWidth, 
            height: window.innerHeight,
          }
        )

        if (contentStyle.right === '25%' && !contentStyle.left === '25%' && !isPortrait) {
          contentStyle.left = '25%'
        }

        if (contentStyle.left === '25%' && !contentStyle.right === '25%' && !isPortrait) {
          contentStyle.right = '25%'
        }

        this.setState({
          containerStyle,
          contentStyle
        })
      } else {
        if ((Math.abs((this.state.rotateNext / 180) % 2) === 1) && (this.state.rotateNext !== 0)) {
          contentStyle.bottom = 0;
          contentStyle.transform = 'rotate(180deg)';
          contentStyle.right = '25%';
          if (!isPortrait) contentStyle.left = '25%';
        }
        else {
          contentStyle.top = 0;
          contentStyle.left = '25%';
          if (!isPortrait) contentStyle.right = '25%';
        }
        
        const containerStyle = {};
        if (isPortrait) {
          containerStyle.width = window.innerHeight * 2;
          containerStyle.height = window.innerHeight * 2;
          containerStyle.transform = `translateX(-25%) rotate(${this.state.rotateNext}deg)`;
        }
        else {
          containerStyle.width = window.innerWidth * 2;
          containerStyle.height = window.innerWidth * 2;
          containerStyle.transform = `rotate(${this.state.rotateNext}deg)`;
        }

        const rotationDirection = Math.random() < 0.5 ? -1 : 1;
        const rotateNext = this.state.rotateNext + 180 * rotationDirection

        this.setState({
          contentStyle,
          containerStyle,
          rotateNext
        });
      }
    }
  };

  constructor() {
    super();
    this.state = {
      rotateNext: 0,
      smallScreen: false
    };
  }

  getChildContext() {
    return {};
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.updateStyles();
      window.addEventListener('resize', () => this.updateStyles(true));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateStyles);
  }

  render() {
    return (
      <Router>
        <Route render={({ location }) => (
          <div id="app">
            <WbNavBar />
            <div class="container" style={this.state.containerStyle}>
              <div class="container__grid">
                <GridBg />
                <TransitionGroup>
                  <Transition
                    key={location.key} 
                    timeout={1250}
                    onEnter={this.onEnter} 
                    onEntering={this.onEntering}
                    onEntered={this.onEntered}
                    onExit={this.onExit}
                    onExiting={this.onExiting}
                    onExited={this.onExited}>
                    <div className="container__content" style={this.state.contentStyle}>
                      <Switch location={location}>
                        <Route exact path="/" component={Artists} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/work" component={Work} />
                        <Route exact path="/class" component={WbClass} />
                        <Route exact path="/shop" component={Merch} />
                        <Route exact path="/philosophy" component={Philosophy} />
                        <Redirect to="/" />
                      </Switch>
                    </div>
                  </Transition>
                </TransitionGroup>
              </div>
            </div>
          </div>
        )} />
      </Router>
    );
  }
}
