import { h } from 'preact';
import style from './style';
import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const WbLogo = (props) => (
	<div class={cx('wb-logo')}>
		<img class={cx('wb-logo__image')} src="/assets/wb_logo.svg" />
	</div>
);

export default WbLogo;
