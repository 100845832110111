import { h } from 'preact';
import { NavLink } from 'react-router-dom';
import style from './style';
import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const NavItem = (props) => (
  <div class={cx('nav-item')}>
    <NavLink activeClassName={cx('nav-item__link--active')} className={cx('nav-item__link')} to={props.to} exact>
      {props.title || props.children}
    </NavLink>
  </div>
);

export default NavItem;
