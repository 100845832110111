import { h } from 'preact';
import { NavLink } from 'react-router-dom';
import style from './style';
import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const NavItemExternal = (props) => (
  <div class={cx('nav-item')}>
    <a className={cx('nav-item__link')} href={props.to} target="_blank" rel="noreferrer">
      {props.title || props.children}
    </a>
  </div>
);

export default NavItemExternal;
