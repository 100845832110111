import { h } from 'preact';
import style from './style';

import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const GridBg = (props) => (
  <div class={cx('grid-bg')} /> 
);

export default GridBg;
